/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { styled } from 'baseui';
import { Radio } from 'baseui/radio';
import { useDispatch, useSelector } from 'react-redux';
import { MonoParagraphMedium } from 'baseui/typography';
import StyledRadioGroup, {
  radioOverrides
} from 'newHirePortal/newHire/containers/YourInfo/HardwareShipping/StyledRadioGroup';
import HardwareTitle from '../HardwareTitle';
import PickLanguage from './PickLanguage';
import { selectLaptop } from '../../../../store/hardware/slice';
import TitleInfo from '../TitleInfo';
import LaptopIcon from '../../../../../../components/icons/Laptop';
import { laptopDevice } from '../../../../store/hardware/selectors';
import { findHardwareByLangOption } from '../../../../util/hardwareHelper';

export const radioStyleOverrides = radioOverrides({ width: '240px' });

export const Wrapper = styled('div', ({ $theme }) => ({
  border: `1px solid ${$theme.colors.mono300}`,
  ':hover': { border: `2px solid ${$theme.colors.backgroundAccent}` },
  borderRadius: '8px',
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '40px'
}));

const PickLaptopFrameV2 = () => {
  const dispatch = useDispatch();
  const laptopData = useSelector(laptopDevice);

  const selectedLaptop = useSelector(
    (state) => state.hardwareSelection.selectedHardware.laptop
  );

  const [laptopDefaultLangOption, setLaptopDefaultLangOption] = useState(
    selectedLaptop
      ? selectedLaptop.language_choices[0].sq_part_number
      : laptopData[0].language_choices[0].sq_part_number
  );

  const selectedLaptopObject = findHardwareByLangOption(
    laptopData,
    laptopDefaultLangOption
  );
  const languageArray = selectedLaptopObject.language_choices;

  useEffect(() => {
    dispatch(selectLaptop(selectedLaptopObject));
  }, [selectedLaptopObject]);

  return (
    <Wrapper data-testid='pick-laptop-v2'>
      <HardwareTitle
        icon={<LaptopIcon size={30} />}
        deviceName='Laptop'
        infoText={['Choose your laptop']}
      />
      <StyledRadioGroup
        currentValue={laptopDefaultLangOption}
        setCurrentValue={setLaptopDefaultLangOption}
        testId='laptop-radio-group'
        radioBtnName='Laptop'
      >
        {laptopData.map((laptop) => (
          <Radio
            overrides={radioStyleOverrides}
            key={laptop.language_choices[0].sq_part_number}
            value={laptop.language_choices[0].sq_part_number}
          >
            <MonoParagraphMedium>{laptop.display_name}</MonoParagraphMedium>
          </Radio>
        ))}
      </StyledRadioGroup>
      <>
        <TitleInfo
          heading='Laptop Language'
          subText='Choose your laptop language'
          marginTop='20px'
          marginLeft='60px'
        />

        <PickLanguage languageArray={languageArray} />
      </>
    </Wrapper>
  );
};

export default PickLaptopFrameV2;
