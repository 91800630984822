import { createAsyncThunk } from '@reduxjs/toolkit';
import { getHardwares as getHardwaresApi } from '../../api/hardwares';
import { handleApiResponse } from '../../../../utils/api_helpers';

export const getHardwares = createAsyncThunk(
  'hardwares/index',
  async (_args, { rejectWithValue }) => {
    try {
      const response = await getHardwaresApi();
      const result = await handleApiResponse(response);
      if (result.ok) {
        return result.data;
      }
      return rejectWithValue(result.error);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
